import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal, Input, Button } from '../components'
import { http, hasViolations } from '../http'


export const DeviceCreateRoute = () => {
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState({ client: '', device: '' })
  const [violations, setViolations] = useState({})

  return (
    <Modal
      heading="Assign pump"
      onClose={() => navigate(-1)}
    >
      <div className="mt-4">
        <Input
          label="Pump ID"
          type="text"
          error={violations['device']}
          onChange={fieldValue => handleChange('device', fieldValue)}
        />
      </div>
      <div className="mt-4 mb-8">
        <Input
          label="Client ID"
          type="text"
          error={violations['client']}
          onChange={fieldValue => handleChange('client', fieldValue)}
        />
      </div>
      <Button.Group>
        <Button.Outlined
          text="Cancel"
          onClick={() => navigate(-1)}
        />
        <Button.Primary
          text="Save"
          onClick={handleCommit}
        />
      </Button.Group>
    </Modal>
  )

  function handleCommit() {
    http.fetch('/b2b.device.attach', formValues).then(res => {
      if (hasViolations(res)) {
        setViolations(res.errors.reduce((acc, cur) => ({...acc, [cur.property]: cur.message}), {}))
        return
      }

      navigate(-1)
    })
  }

  function handleChange(name, value) {
    setFormValues(prev => ({ ...prev, [name]: value }))
  }
}