import { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Modal, Input, Button } from '../components'
import { http, hasViolations } from '../http'
import { useClientRouterContext } from './ClientRouterContext'

export const ClientCreateRoute = () => {
  const navigate = useNavigate()
  const setStore = useClientRouterContext()[1]
  const [formValues, setFormValues] = useState({ code: '' })
  const [violations, setViolations] = useState({})

  return (
    <Modal
      heading="Add client"
      onClose={() => { navigate(-1) }}
    >
      <div className="mt-4 mb-8">
        <Input
          label="Client ID"
          type="text"
          error={violations['code']}
          onChange={fieldValue => handleChange('code', fieldValue)}
        />
      </div>
      <Button.Group>
        <Button.Outlined
          component={Link}
          to="/clients"
          text="Cancel"
        />
        <Button.Primary
          text="Save"
          onClick={handleCommit}
        />
      </Button.Group>
    </Modal>
  )

  function handleCommit() {
    http.fetch('/b2b.client.create', formValues).then(res => {
      if (hasViolations(res)) {
        setViolations(res.errors.reduce((acc, cur) => ({ ...acc, [cur.property]: cur.message }), {}))
        return
      }

      setStore(prev => {
        prev.status = 'loading'
      })
      navigate('/clients')
    })
  }

  function handleChange(name, value) {
    setFormValues(prev => ({ ...prev, [name]: value }))
  }
}