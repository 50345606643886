import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {Modal, Input, Button, Radio } from '../components'
import { http, hasViolations } from '../http'

export const ClientDeliveryCreateRoute = () => {
  const { client } = useParams()
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState({ client, zeroing: false, volume: 0 })
  const [violations, setViolations] = useState({})

  return (
    <Modal
      heading="New delivery"
      onClose={() => navigate(-1)}
    >
      <div className="mt-4">
        <Input
          label="Volume"
          type="text"
          error={violations['volume']}
          onChange={fieldValue => handleChange('volume', Number(fieldValue * 1000))}
        />
      </div>
      <div className="mt-4 mb-8">
        <div className="fs-6">Balance</div>
        <div className="mt-1">
          <Radio
            label="Keep"
            name="zeroing"
            defaultChecked={true}
            onChange={() => handleChange('zeroing', false)}
          />
        </div>
        <div className="mt-1">
          <Radio
            label="Reset"
            name="zeroing"
            onChange={() => handleChange('zeroing', true)}
          />
        </div>
      </div>
      <Button.Group>
        <Button.Outlined
          text="Cancel"
          onClick={() => navigate(-1)}
        />
        <Button.Primary
          text="Save"
          onClick={handleCommit}
        />
      </Button.Group>
    </Modal>
  )

  function handleCommit() {
    http.fetch('/b2b.delivery.create', formValues).then(res => {
      if (hasViolations(res)) {
        setViolations(res.errors.reduce((acc, cur) => ({...acc, [cur.property]: cur.message}), {}))
        return
      }

      navigate(-1)
    })
  }

  function handleChange(name, value) {
    setFormValues(prev => ({...prev, [name]: value}))
  }
}