import { Routes, Route } from 'react-router-dom'
import { wrapWithQueryParams } from '../components'
import { DeliveryListRoute } from './DeliveryListRoute'

const DeliverySearchRoute = wrapWithQueryParams(DeliveryListRoute)

export const DeliveryRouter = () => {
  return (
    <Routes>
      <Route index element={<DeliveryListRoute/>}/>
      <Route path="search" element={<DeliverySearchRoute/>}/>
    </Routes>
  )
}