import styles from './Radio.module.css'

export const Radio = props => {
  const { label, onChange, ...rest } = props

  return (
    <label className={styles.root}>
      <span className={styles.control}>
        <input
          type="radio"
          onChange={e => handleChange(e.target.checked)}
          {...rest}
        />
        <span/>
      </span>
      <span className={styles.label}>{label}</span>
    </label>
  )

  function handleChange(payload) {
    if (!onChange) {
      return
    }

    onChange(payload)
  }
}