import { to } from '../helpers'

export const Datetime = props => {
  const { value, variant = 'datetime' } = props

  if (null == value || '' === value) {
    return '—'
  }

  const [date, time] = to.DateTime(value, { raw: true })

  if ('date' === variant) {
    return (
      <span>
        <span>{date}</span>
      </span>
    )
  }

  return (
    <span>
      <span>{date}</span>
      <span className="d-block text-muted">{time}</span>
    </span>
  )
}