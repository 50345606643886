import { useImmer } from 'use-immer'
import { Routes, Route } from 'react-router-dom'
import { ClientRouterContext } from './ClientRouterContext'
import { ClientListRoute } from './ClientListRoute'
import { ClientCreateRoute } from './ClientCreateRoute'
import { ClientEditRoute } from './ClientEditRoute'
import { ClientDeliveryCreateRoute } from './ClientDeliveryCreateRoute'

export const ClientRouter = () => {
  const [store, setStore] = useImmer({ status: 'loading', data: {}, query: { orderBy: { restockAt: -1 }, active: true, skip: 0, limit: 10 } })

  return (
    <ClientRouterContext.Provider value={[store, setStore]}>
      <Routes>
        <Route index element={<ClientListRoute/>}/>
        <Route path="new" element={<ClientCreateRoute/>}/>
        <Route path=":client/edit" element={<ClientEditRoute/>}/>
        <Route path=":client/deliveries/new" element={<ClientDeliveryCreateRoute/>}/>
      </Routes>
    </ClientRouterContext.Provider>
  )
}