import styles from './Pagination.module.css'

const perPageOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90]

export const Pagination = props => {
  const { skip, limit, onChange } = props

  return (
    <div className={styles.root}>
      <div className={styles.input}>
        <div className={styles.label}>Rows per page:</div>
        <select
          className={styles.select}
          value={limit}
          onChange={e => {
            handleLimitChange(Number(e.target.value))
          }}
        >
          {perPageOptions.map((cur, i) => <option key={i} value={cur}>{cur}</option>)}
        </select>
      </div>
      <div className={styles.group}>
        <div
          className={styles.btn}
          onClick={() => handleSkipChange(skip - limit)}
        >
          <svg>
            <use href="#caret-left"/>
          </svg>
        </div>
        <div className={styles.divider}/>
        <div
          className={styles.btn}
          onClick={() => handleSkipChange(skip + limit)}
        >
          <svg>
            <use href="#caret-right"/>
          </svg>
        </div>
      </div>
    </div>
  )

  function handleSkipChange(payload) {
    if (payload < 0) {
      return
    }

    handleChange({ limit, skip: payload })
  }

  function handleLimitChange(payload) {
    handleChange({ skip: 0, limit: payload })
  }

  function handleChange(payload) {
    if (!onChange) {
      return
    }

    onChange(payload)
  }
}