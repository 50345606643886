import styles from './Heading.module.css'

export const Heading = props => {
  const { text, button } = props

  return (
    <div className={styles.root}>
      <h1>{text}</h1>
      {button}
    </div>
  )
}