const toString = Object.prototype.toString
const isPlainObject = o => {
  if ('[object Object]' !== toString.call(o) ) {
    return false
  }

  const proto = Object.getPrototypeOf(o)

  return null === proto || proto === Object.prototype || null === Object.getPrototypeOf(proto)
}
const isVisitable = o => isPlainObject(o) || Array.isArray(o)
const isFlatArray = o => Array.isArray(o) && !o.some(c => isVisitable(c))

export const flatten = payload => {
  const ret = []

  if (null == payload) {
    return ret
  }

  build(payload)

  return ret

  function visitor(value, key, path) {
    if (value && !path && typeof value === 'object') {
      if (isFlatArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const el = value[i]

          if (undefined === el) {
            continue
          }

          ret.push({ name: convertKey([key], i), value: convertValue(el) })
        }

        return false
      }
    }

    if (isVisitable(value)) {
      return true
    }

    ret.push({ name: convertKey(path, key), value: convertValue(value)})

    return false
  }

  function build(value, path) {
    if (undefined === value) {
      return
    }

    forEach(value, (el, key) => {
      if (undefined === el) {
        return
      }

      const result = visitor(el, key, path)

      if (true === result) {
        build(el, path ? path.concat(key) : [key])
      }
    })
  }

  function convertKey(path, key) {
    if (!path) {
      return key
    }

    return path.concat(key).map((token, i) => {
      return i ? `[${token}]` : token
    }).join('')
  }

  function convertValue(value) {
    if (value === null || value === '') {
      return ''
    }

    if (value instanceof File || value instanceof Blob) {
      return value
    }

    return value.toString()
  }

  function forEach(o, fn) {
    if (null === o || typeof o === 'undefined') {
      return
    }

    if (typeof o !== 'object') {
      o = [o]
    }

    if (Array.isArray(o)) {
      for (let i = 0; i < o.length; i++) {
        fn.call(null, o[i], i, o)
      }
    } else {
      const keys = Object.keys(o)

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]

        fn.call(null, o[key], key, o)
      }
    }
  }
}