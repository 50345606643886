import { Link as RouterLink } from 'react-router-dom'
import styles from './Button.module.css'

const Link = props => {
  const { label, ...rest } = props

  return (
    <RouterLink
      className={styles.link}
      {...rest}
    >
      <span>
        <svg>
          <use href="#plus-circle"/>
        </svg>
        {label}
      </span>
    </RouterLink>
  )
}

const createVariant = className => props => {
  const { text, component: Component = 'div', ...rest } = props

  return (
    <Component className={styles[className]} {...rest}>
      {text}
    </Component>
  )
}

const Group = props => {
  const { children } = props

  return (
    <div className={styles.group}>
      {children}
    </div>
  )
}

export const Button = {
  Link,
  Group,
  Primary: createVariant('primary'),
  Outlined: createVariant('outlined'),
}