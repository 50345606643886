import { useEffect } from 'react'
import { useImmer } from 'use-immer'
import { Link } from 'react-router-dom'
import { normalizr, to } from '../helpers'
import { http } from '../http'
import { Table, Heading, Datetime, Pagination, Button } from '../components'
import { DeliveryContext, useDeliveryContext } from './DeliveryContext'

const DeliveryList = () => {
  const [{ data, query }, setStore] = useDeliveryContext()

  return (
    <>
      <Heading
        text="Deliveries"
        button={
          query.client && <Button.Link to={`/clients/${query.client}/deliveries/new`} label="Add delivery"/>
        }
      />
      <Table.Container empty={0 === data.ids.length}>
        <Table.Head>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Client</th>
            <th>Delivered</th>
            <th>Remained</th>
            <th>Total</th>
          </tr>
        </Table.Head>
        <Table.Body>
          {data.ids.map(id => data.byId[id]).map(cur => (
            <tr key={cur.id}>
              <td>{cur.id}</td>
              <td>
                <Datetime value={cur.createdAt}/>
              </td>
              <td>
                <Link to={`/deliveries/search?client=${cur.client}`}>{cur.client}</Link>
              </td>
              <td>{to.L(cur.volume)} L</td>
              <td>{to.L(cur.balance)} L</td>
              <td>
                {to.L(cur.volume + cur.balance)} L
              </td>
            </tr>
          ))}
        </Table.Body>
      </Table.Container>
      <Pagination
        skip={query.skip}
        limit={query.limit}
        onChange={handlePaginationChange}
      />
    </>
  )

  function handlePaginationChange(payload) {
    setStore(draft => {
      draft.status = 'pending'
      draft.query.skip = payload.skip
      draft.query.limit = payload.limit
    })
  }
}
export const DeliveryListRoute = props => {
  const { query: initialQuery } = props
  const [store, setStore] = useImmer({ status: 'loading', data: {}, query: { skip: 0, limit: 10, ...initialQuery } })
  const { status, query } = store

  useEffect(() => {
    http.fetch('/b2b.delivery.list', query).then(res => {
      setStore(draft => {
        if (!res.ok) {
          draft.status = 'rejected'
          return
        }

        draft.data = normalizr(res.data)
        draft.status = 'resolved'
      })
    })
  }, [query, setStore])

  if ('loading' === status) {
    return 'Loading...'
  } else if ('rejected' === status) {
    return 'Failed to load resource.'
  }

  return (
    <DeliveryContext.Provider value={[store, setStore]}>
      <DeliveryList/>
    </DeliveryContext.Provider>
  )
}