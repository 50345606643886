import { useSyncExternalStore } from 'react'
import { SecurityContext } from './http'
import { Homepage } from './Homepage'
import { App } from './App'

function Root() {
  const isLoggedIn = useSyncExternalStore(SecurityContext.subscribe, SecurityContext.getSnapshot)

  if (!isLoggedIn) {
    return <Homepage/>
  }

  return <App/>
}
export default Root
