export { Table } from './Table'
export { Button } from './Button'
export { Input } from './Input'
export { Heading } from './Heading'
export { Switch } from './Switch'
export { Pagination } from './Pagination'
export { TabList } from './Tabs'
export { Datetime } from './Datetime'
export { Modal } from './Modal'
export { Radio } from './Radio'

export const wrapWithQueryParams = Component => () => {
  const params = new URLSearchParams(window.location.search)
  const query = {}

  for (const [key, value] of params) {
    query[key] = value
  }

  return <Component query={query}/>
}