import { clsx } from '../helpers'
import styles from './Table.module.css'

const Container = props => {
  const { empty, children } = props

  if (empty) {
    return (
      <div className={styles.container}>
        <div className={styles.placeholder}>No results found.</div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        {children}
      </table>
    </div>
  )
}

const Head = props => {
  const { children } = props

  return (
    <thead
      className={styles.thead}
      children={children}
    />
  )
}
const Body = props => {
  const { children } = props

  return (
    <tbody
      className={styles.tbody}
      children={children}
    />
  )
}

const Sortable = props => {
  const { label, direction, onClick } = props

  return (
    <span className={clsx(styles.sortable, !!direction && styles.active)} onClick={handleClick}>
      <span>{label}</span>
      <svg>
        {null == direction && <use href="#unfold-more"/>}
        {1 === direction && <use href="#small-caret-up"/>}
        {-1 === direction && <use href="#small-caret-down"/>}
      </svg>
    </span>
  )

  function handleClick() {
    if (!onClick) {
      return
    }

    if (null == direction) {
      onClick(1)
    } else if (1 === direction) {
      onClick(-1)
    } else if (-1 === direction) {
      onClick(1)
    }
  }
}

export const Table = {
  Sortable,
  Container,
  Head,
  Body,
}