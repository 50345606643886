import { useParams, useNavigate } from 'react-router-dom'
import { Modal } from '../components'
import { MedianSpending } from './MedianSpending'

export const DeviceEditRoute = () => {
  const navigate = useNavigate()
  const { device } = useParams()

  return (
    <Modal heading="Manage pump" onClose={() => navigate(-1)}>
      <div className="mt-4">
        <div className="fs-6">Pump ID</div>
        <div className="fs-5">{device}</div>
      </div>
      <MedianSpending device={device}/>
    </Modal>
  )
}