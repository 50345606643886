import styles from './Input.module.css'

export const Input = props => {
  const { label, error, onChange, ...rest } = props

  return (
    <div className={styles.root}>
      <div className={styles.label}>{label}</div>
      <input
        {...rest}
        className={styles.field}
        onChange={e => handleChange(e.target.value)}
      />
      {error && <div className={styles.error}>{error}</div>}
    </div>
  )

  function handleChange(fieldValue) {
    if (!onChange) {
      return
    }

    onChange(fieldValue)
  }
}