import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { http } from '../http'
import { normalizr, to } from '../helpers'
import { DeviceContext, useDeviceContext } from './DeviceContext'
import { Table, TabList, Heading, Datetime, Pagination, Button } from '../components'

const DeviceList = () => {
  const [{ data, query }, setStore] = useDeviceContext()

  return (
    <>
      <Heading
        text="Pumps"
        button={<Button.Link to="/devices/new" label="Assign pump" />}
      />
      <TabList
        selected={query.status - 1}
        choices={[
          ['Active', () => handleQueryChange(draft => draft.status = 1)],
          ['Not active', () => handleQueryChange(draft => draft.status = 2)],
          ['Retired', () => handleQueryChange(draft => draft.status = 3)],
        ]}
      />
      <Table.Container empty={0 === data.ids.length}>
        <Table.Head>
          <tr>
            <th>
              <Table.Sortable
                label="ID"
                direction={query.orderBy.id}
                onClick={handleOrderByChange('id')}
              />
            </th>
            <th>Client</th>
            <th>
              <Table.Sortable
                label="Mileage"
                direction={query.orderBy.volume}
                onClick={handleOrderByChange('volume')}
              />
            </th>
            <th>Last mileage</th>
            <th>
              <Table.Sortable
                label="Activated"
                direction={query.orderBy.connectedAt}
                onClick={handleOrderByChange('connectedAt')}
              />
            </th>
            <th>
              <Table.Sortable
                label="Updated"
                direction={query.orderBy.updatedAt}
                onClick={handleOrderByChange('updatedAt')}
              />
            </th>
          </tr>
        </Table.Head>
        <Table.Body>
          {data.ids.map(id => data.byId[id]).map(cur => (
            <tr key={cur.id}>
              <td>
                <Link to={`/devices/${cur.id}/edit`}>{cur.id}</Link>
              </td>
              <td>{cur.client}</td>
              <td>{null == cur.volume ? '0' : to.L(cur.volume)} L</td>
              <td>—</td>
              <td>
                <Datetime value={cur.connectedAt}/>
              </td>
              <td>
                <Datetime value={cur.updatedAt}/>
              </td>
            </tr>
          ))}
        </Table.Body>
      </Table.Container>
      <Pagination
        skip={query.skip}
        limit={query.limit}
        onChange={handlePaginationChange}
      />
    </>
  )

  function handlePaginationChange(payload) {
    setStore(draft => {
      draft.status = 'pending'
      draft.query.skip = payload.skip
      draft.query.limit = payload.limit
    })
  }

  function handleQueryChange(reducer) {
    setStore(draft => {
      reducer(draft.query)
      draft.status = 'pending'
      draft.query.skip = 0
    })
  }

  function handleOrderByChange(property) {
    return direction => {
      handleQueryChange(draft => {
        draft.orderBy = { [property]: direction }
      })
    }
  }
}

export const DeviceListRoute = props => {
  const { query: initialQuery } = props
  const [store, setStore] = useImmer({
    status: 'loading',
    data: {},
    query: {
      status: 1,
      skip: 0, limit: 10,
      orderBy: { id: -1 },
      ...initialQuery
    }
  })
  const { status, query } = store

  useEffect(() => {
    http.fetch('/b2b.device.list', query).then(res => {
      setStore(draft => {
        if (!res.ok) {
          draft.status = 'rejected'
          return
        }

        draft.data = normalizr(res.data)
        draft.status = 'resolved'
      })
    })
  }, [query, setStore])

  if ('loading' === status) {
    return 'Loading...'
  } else if ('rejected' === status) {
    return 'Failed to load resource.'
  }

  return (
    <DeviceContext.Provider value={[store, setStore]}>
      <DeviceList/>
    </DeviceContext.Provider>
  )
}