import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table, TabList, Heading, Datetime, Pagination, Button } from '../components'
import { useClientRouterContext } from './ClientRouterContext'
import { http } from  '../http'
import { normalizr, to } from '../helpers'

export const ClientListRoute = () => {
  const [{ data, status, query }, setStore] = useClientRouterContext()

  useEffect(() => {
    http.fetch('/b2b.client.list', query).then(res => {
      setStore(draft => {
        if (!res.ok) {
          draft.status = 'rejected'
          return
        }

        draft.data = normalizr(res.data)
        draft.status = 'resolved'
      })
    })
  }, [query, setStore])

  if ('loading' === status) {
    return 'Loading...'
  } else if ('rejected' === status) {
    return 'Failed to load resource.'
  }

  return (
    <>
      <Heading
        text="Clients"
        button={
          <Button.Link to="new" label="Add client"/>
        }
      />
      <TabList
        selected={query.active ? 0 : 1}
        choices={[
          ['Active', () => handleQueryChange(draft => draft.active = true)],
          ['Do not disturb', () => handleQueryChange(draft => draft.active = false)],
        ]}
      />
      <Table.Container empty={0 === data.ids.length}>
        <Table.Head>
          <tr>
            <th>
              <Table.Sortable
                label="ID"
                direction={query.orderBy.id}
                onClick={handleOrderByChange('id')}
              />
            </th>
            <th>
              <Table.Sortable
                label="Thirst"
                direction={query.orderBy.restockAt}
                onClick={handleOrderByChange('restockAt')}
              />
            </th>
            <th>Balance</th>
            <th>Last delivery</th>
            <th>
              <Table.Sortable
                label="Last mileage"
                direction={query.orderBy.volumeFromLastRestock}
                onClick={handleOrderByChange('volumeFromLastRestock')}
              />
            </th>
            <th>
              <Table.Sortable
                label="Last delivery date"
                direction={query.orderBy.restockedAt}
                onClick={handleOrderByChange('restockedAt')}
              />
            </th>
            <th>
              <Table.Sortable
                label="Pumps"
                direction={query.orderBy.deviceCount}
                onClick={handleOrderByChange('deviceCount')}
              />
            </th>
            <th>
              <Table.Sortable
                label="Total deliveries"
                direction={query.orderBy.restockedVolumeSum}
                onClick={handleOrderByChange('restockedVolumeSum')}
              />
            </th>
            <th/>
          </tr>
        </Table.Head>
        <Table.Body>
          {data.ids.map(id => data.byId[id]).map(cur => (
            <tr key={cur.id}>
              <td>
                <Link to={`${cur.id}/edit`} children={cur.id}/>
              </td>
              <td>
                <Datetime value={cur.restockAt} variant="date"/>
              </td>
              <td>{null == cur.remainder ? '0' : `${cur.remainder}`}%</td>
              <td>{to.L(cur.restockedVolume)} L</td>
              <td>{to.L(cur.volumeFromLastRestock)} L</td>
              <td>
                <Datetime value={cur.restockedAt}/>
              </td>
              <td>
                <Link to={`/devices/search?client=${cur.id}`}>{cur.deviceCount}</Link>
              </td>
              <td>{null === cur.restockedVolumeSum ? '0': `${to.L(cur.restockedVolumeSum)} L`}</td>
              <td>
                <Link to={`/clients/${cur.id}/deliveries/new`} className="icon-link">
                  <svg>
                    <use href="#cart"/>
                  </svg>
                </Link>
              </td>
            </tr>
          ))}
        </Table.Body>
      </Table.Container>
      <Pagination
        skip={query.skip}
        limit={query.limit}
        onChange={handlePaginationChange}
      />
    </>
  )

  function handleQueryChange(reducer) {
    setStore(draft => {
      reducer(draft.query)
      draft.status = 'pending'
      draft.query.skip = 0
    })
  }
  function handlePaginationChange(payload) {
    setStore(draft => {
      draft.status = 'pending'
      draft.query.skip = payload.skip
      draft.query.limit = payload.limit
    })
  }

  function handleOrderByChange(property) {
    return direction => {
      handleQueryChange(draft => {
        draft.orderBy = { [property]: direction }
      })
    }
  }
}