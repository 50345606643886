import { createPortal } from 'react-dom'
import styles from './Modal.module.css'
export const Modal = props => {
  const { heading, children, onClose } = props

  const root = (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.close} onClick={onClose}>
          <svg><use href="#close"/></svg>
        </div>
        <div className={styles.heading}>{heading}</div>
        {children}
      </div>
    </div>
  )

  return createPortal(root, document.body)
}
