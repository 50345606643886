import { Router } from '../Router'
import { Navigation } from './Navigation'
import styles from './App.module.css'

export const App = () => {
  return (
    <div className={styles.root}>
      <div className={styles.navigation}>
        <Navigation/>
      </div>
      <div className={styles.container}>
        <Router/>
      </div>
    </div>
  )
}