import { Routes, Route } from 'react-router-dom'
import { LoginForm } from './LoginForm'
import { PasswordResetRoute } from './PasswordResetRoute'
import { PasswordUpdateRoute } from './PasswordUpdateRoute'

export const Homepage = () => {
  return (
    <Routes>
      <Route path="/password-reset/:token" element={<PasswordUpdateRoute/>}/>
      <Route path="/password-reset" element={<PasswordResetRoute/>}/>
      <Route index element={<LoginForm/>}/>
    </Routes>
  )
}