import { useState } from 'react'
import { http, hasViolations } from '../http'
import styles from './LoginForm.module.css'

export const PasswordResetRoute = () => {
  const [error, setError] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [formValues, setFormValues] = useState({ username: '' })

  return (
    <div className={styles.root}>
      <form className={styles.form} noValidate={true} autoComplete="off" onSubmit={handleCommit}>
        <svg className={styles.brand}>
          <use href="#brand"/>
        </svg>
        <div className={styles.heading}>Forgot password?</div>
        <div className={styles.stack}>
          <input
            id="username"
            placeholder="Email address"
            type="email"
            className={styles.control}
            onChange={e => handleChange('username', e.target.value)}
          />
        </div>
        <button
          disabled={disabled || !formValues.username}
          className={styles.btn}
          type="submit"
          children={disabled ? 'Loading...' : 'Submit'}
        />
        {error && <div className="mt-2 text-danger">{error}</div>}
      </form>
    </div>
  )

  function handleCommit(e) {
    e.preventDefault()

    setDisabled(true)

    http.fetch('/iam.password.reset', {
      ...formValues,
      resetUrl: `${window.location.href}/{{token}}`
    }).then(res => {
      if (hasViolations(res)) {
        setDisabled(false)
        setError(res.message)
        return
      }
    })
  }

  function handleChange(name, value) {
    setFormValues(prev => ({ ...prev, [name]: value }))
  }
}