import { Routes, Route } from 'react-router-dom'
import { wrapWithQueryParams } from '../components'
import { DeviceListRoute } from './DeviceListRoute'
import { DeviceEditRoute } from './DeviceEditRoute'
import { DeviceCreateRoute } from './DeviceCreateRoute'

const DeviceSearchRoute = wrapWithQueryParams(DeviceListRoute)
export const DeviceRouter = () => {
  return (
    <Routes>
      <Route index element={<DeviceListRoute/>}/>
      <Route path="search" element={<DeviceSearchRoute/>}/>
      <Route path="new" element={<DeviceCreateRoute/>}/>
      <Route path=":device/edit" element={<DeviceEditRoute/>}/>
    </Routes>
  )
}