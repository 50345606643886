import { clsx } from '../helpers'
import styles from './Tabs.module.css'
export const TabList = props => {
  const { choices, selected } = props

  return (
    <div className={styles.root}>
      {choices.map((cur, i) => {
        const [label, onClick] = cur

        return (
          <div
            key={i}
            className={clsx(styles.tab, i === selected && styles.selected)}
            children={label}
            onClick={() => {
              if (i === selected) {
                return
              }

              onClick()
            }}
          />
        )
      })}
    </div>
  )
}