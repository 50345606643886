import { useState } from 'react'
import { Link } from 'react-router-dom'
import { http, SecurityContext } from '../http'
import styles from './LoginForm.module.css'

export const LoginForm = () => {
  const [error, setError] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [formValues, setFormValues] = useState({ username: '', password: '' })

  return (
    <div className={styles.root}>
      <form className={styles.form} noValidate={true} autoComplete="off" onSubmit={handleCommit}>
        <svg className={styles.brand}>
          <use href="#brand"/>
        </svg>
        <div className={styles.heading}>Welcome back to Swirp</div>
        <div className={styles.stack}>
          <input
            id="username"
            placeholder="Username"
            type="text"
            className={styles.control}
            onChange={e => handleChange('username', e.target.value)}
          />
          <input
            id="password"
            placeholder="Password"
            type="password"
            className={styles.control}
            onChange={e => handleChange('password', e.target.value)}
          />
          <div className={styles.addon}>
            <Link to="/password-reset">Forgot password?</Link>
          </div>
        </div>
        <button
          disabled={disabled || !formValues.username || !formValues.password}
          className={styles.btn}
          type="submit"
          children={disabled ? 'Loading...' : 'Sign in'}
        />
        {error && <div className="mt-2 text-danger">{error}</div>}
      </form>
    </div>
  )

  function handleCommit(e) {
    e.preventDefault()

    setDisabled(true)

    http.fetch('/iam.token.create', formValues).then(res => {
      if (!res.ok) {
        setDisabled(false)

        if ('9cb31350-c9e0-4d45-88ec-e680902a3417' === res.code) {
          setError('Invalid credentials.')
        } else {
          setError('Something went wrong.')
        }

        return
      }

      SecurityContext.logIn(res.data)
    })
  }

  function handleChange(name, value) {
    setFormValues(prev => ({ ...prev, [name]: value }))
  }
}