import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { http, hasViolations } from '../http'
import styles from './LoginForm.module.css'

export const PasswordUpdateRoute = () => {
  const navigate = useNavigate()
  const match = useParams()
  const [error, setError] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [formValues, setFormValues] = useState({ 'password[first]': '', 'password[second]': '' })

  return (
    <div className={styles.root}>
      <form noValidate={true} autoComplete="off" onSubmit={handleCommit}>
        <h1 className="h3 fw-normal text-center">New password</h1>
        <div className={styles.stack}>
          <div className="form-floating">
            <input
              id="password_first"
              placeholder="New password"
              type="password"
              className="form-control"
              onChange={e => handleChange('password[first]', e.target.value)}
            />
            <label htmlFor="password_first">New password</label>
          </div>
          <div className="form-floating">
            <input
              id="password_second"
              placeholder="Confirm password"
              type="password"
              className="form-control"
              onChange={e => handleChange('password[second]', e.target.value)}
            />
            <label htmlFor="password_second">Confirm password</label>
          </div>
        </div>
        <button
          disabled={disabled || Object.keys(formValues).some(key => '' === formValues[key])}
          className="btn w-100 py-2 btn-primary"
          type="submit"
          children={disabled ? 'Loading...' : 'Submit'}
        />
        {error && <div className="mt-2 text-danger">{error}</div>}
      </form>
    </div>
  )

  function handleCommit(e) {
    e.preventDefault()
    setDisabled(true)
    http.fetch('/iam.password.update', {
      ...formValues,
      token: match.token
    }).then(res => {
      if (res.ok) {
        navigate('/')
        return
      }

      if (hasViolations(res)) {
        setDisabled(false)

        for (const err of res.errors) {
          switch (err.property) {
            case 'token':
              setError('Token is not a valid.')
              return
            case 'password.first':
              setError('Passwords do not match.')
              return
            default:
          }
        }
      } else {
        setError('Something went wrong.')
      }
    })
  }

  function handleChange(name, value) {
    setFormValues(prev => ({ ...prev, [name]: value }))
  }
}