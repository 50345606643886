import { useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { Modal, Input, Button, Radio } from '../components'
import { http, hasViolations } from '../http'
import { useClientRouterContext } from './ClientRouterContext'

export const ClientEditRoute = () => {
  const navigate = useNavigate()
  const { client } = useParams()
  const [{ data }] = useClientRouterContext()
  const record = data.byId[client]
  const [formValues, setFormValues] = useState({
    client: record.id,
    code: record.code,
    active: record.active,
  })
  const [violations, setViolations] = useState({})

  return (
    <Modal heading="Manage client" onClose={() => navigate(-1)}>
      <div className="mt-4">
        <Input
          label="Client ID"
          type="text"
          error={violations['code']}
          defaultValue={record.code}
          onChange={fieldValue => handleChange('code', fieldValue)}
        />
      </div>
      <div className="mt-4">
        <div className="fs-6">Status</div>
        <div className="mt-1">
          <Radio
            label="Active"
            name="status"
            defaultChecked={record.active}
            onChange={() => handleChange('active', true)}
          />
        </div>
        <div className="mt-1">
          <Radio
            label="Do not disturb"
            name="status"
            defaultChecked={!record.active}
            onChange={() => handleChange('active', false)}
          />
        </div>
      </div>
      <div className="mt-4 mb-8">
        <div className="fs-6">Pumps</div>
        <div className="fs-5">
          <Link to={`/devices/search?client=${record.id}`}>{record.deviceCount}</Link>
        </div>
      </div>
      <Button.Group>
        <Button.Outlined
          component={Link}
          to="/clients"
          text="Cancel"
        />
        <Button.Primary
          text="Save"
          onClick={handleCommit}
        />
      </Button.Group>
    </Modal>
  )

  function handleCommit() {
    http.fetch('/b2b.client.edit', formValues).then(res => {
      if (hasViolations(res)) {
        setViolations(res.errors.reduce((acc, cur) => ({...acc, [cur.property]: cur.message}), {}))
        return
      }

      navigate(-1)
    })
  }

  function handleChange(name, value) {
    setFormValues(prev => ({...prev, [name]: value}))
  }
}