import { useEffect, useState } from 'react'
import { to } from '../helpers'
import { http } from '../http'
import styles from './MedianSpending.module.css'

const days = [1, 2, 3, 4, 5, 6, 0]
const daysOfWeek = { 1: 'Mon', 2: 'Tue', 3: 'Wed', 4: 'Thu', 5: 'Fri', 6: 'Sat', 0: 'Sun' }

export const MedianSpending = props => {
  const { device } = props
  const [status, setStatus] = useState('loading')
  const [byDay, setData] = useState({})

  useEffect(() => {
    const payload = { device }

    http.fetch('/b2b.device.median', payload).then(res => {
      if (!res.ok) {
        setStatus('rejected')
        return
      }

      setData(res.data.reduce((acc, cur) => ({ ...acc, [cur.dayOfWeek]: cur.volume }), {}))
      setStatus('resolved')
    })

  }, [device])

  return (
    <div className={styles.root}>
      <div className={styles.label}>Median spending</div>
      <Loader status={status}>
        <div className={styles.table}>
          {days.map(i => (
            <div className={styles.cell} key={i}>
              <span>{daysOfWeek[i]}</span>
              {Math.round(to.L(byDay[i]) * 10) / 10}
            </div>
          ))}
        </div>
      </Loader>

    </div>
  )
}

function Loader(props) {
  const {children, status } = props

  if ('loading' === status) {
    return <div className={styles.loader}>Loading...</div>
  } else if ('rejected' === status) {
    return <div className={styles.loader}>Failed to load resource.</div>
  }

  return children
}