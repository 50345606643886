import axios from 'axios'

export const isCancel = axios.isCancel

const BASE_URL = process.env.REACT_APP_API_BASE_URL
export const transport = axios.create({
  baseURL: BASE_URL,
  validateStatus: status => status >= 200 && status < 500
})

const registerAccessTokenInterceptor = () => {
  const store = {
    stack: [],
    isRefreshing: false,
    accessToken: resolveToken(window.localStorage.getItem('accessToken')),
    listeners: [],
  }

  store.handler = transport.interceptors.request.use(request => {
    request = { skipAuthorization: null == store.accessToken?.id, ...request }

    if (request.skipAuthorization) {
      return request
    } else if (store.isRefreshing) {
      return new Promise(resolve => {
        store.stack.push(resolve)
      }).then(token => {
        return applyToken(request, token)
      })
    } else if (store.accessToken && isTokenExpiring(store.accessToken.exp)) {
      store.isRefreshing = true

      return refreshAccessToken().then(res => {
        if (!res.ok) {
          logOut()
          throw new axios.Cancel('Request call failed')
        }

        const accessToken = res.data.accessToken
        window.localStorage.setItem('accessToken', accessToken)

        store.accessToken = resolveToken(accessToken)
        store.stack.forEach(next => next(accessToken))
        store.stack.length = 0
        store.isRefreshing = false

        return applyToken(request, accessToken)
      })
    } else if (null == store.accessToken?.id) {
      throw new axios.Cancel('Request call failed')
    }

    return applyToken(request, store.accessToken.id)
  })

  return {
    subscribe,
    getSnapshot,
    logIn,
    logOut,
  }

  function logIn(options) {
    window.localStorage.setItem('accessToken', options.accessToken)
    window.localStorage.setItem('refreshToken', options.refreshToken)
    store.accessToken = resolveToken(options.accessToken)
    dispatch()
  }

  function logOut() {
    window.localStorage.removeItem('accessToken')
    window.localStorage.removeItem('refreshToken')
    store.accessToken = null
    dispatch()
  }

  function subscribe(listener) {
    store.listeners = store.listeners.concat(listener)

    return () => {
      store.listeners = store.listeners.filter(l => l !== listener)
    }
  }

  function dispatch() {
    for (let listener of store.listeners) {
      listener()
    }
  }

  function getSnapshot() {
    return !(null == store.accessToken)
  }

  function applyToken(request, token) {
    request.headers['Authorization'] = `Bearer ${token}`

    return request
  }

  function refreshAccessToken() {
    const refreshToken = window.localStorage.getItem('refreshToken')

    if (!refreshToken) {
      throw new axios.Cancel('Request call failed')
    }

    return http.fetch('/iam.token.refresh', { refreshToken }, {
      validateStatus: status => status >= 200 && status < 400,
      skipAuthorization: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }

  function isTokenExpiring(exp = 0) {
    return exp <= Date.now() + 5 * 60 * 1000
  }

  function resolveToken(token) {
    if (!token) {
      return null
    }

    try {
      let ret = token.split('.')[1]
      ret = ret.replace(/-/g, '+').replace(/_/g, '/')
      ret = window.atob(ret).replace(/(.)/g, (m, p) => {
        return '%' + ('00' + p.charCodeAt(0).toString(16)).slice(-2)
      })

      return { id: token, exp: JSON.parse(decodeURIComponent(ret)).exp * 1000  }
    } catch (err) {
      console.error(err)
      return null
    }
  }
}

const request = (url, payload, options) => {
  if (payload) {
    return transport.post(url, payload, {
      ...options,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }

  return transport.get(url, options)
}

export const http = {
  fetch: async (url, payload, options) => {
    try {
      const res = await request(url, payload, options)

      return res.data
    } catch(e) {
      if (e.response) {
        return e.response.data
      }

      console.error(e)

      return { ok: false }
    }
  },
}

export const SecurityContext= registerAccessTokenInterceptor()

export const hasViolations = res => !res.ok && '2e457580-0990-45b0-a3e4-f2a88e290143' === res.code