import { Routes, Route, Navigate } from 'react-router-dom'
import { ClientRouter } from './Client'
import { DeliveryRouter } from './Delivery'
import { DeviceRouter } from './Device'

export const Router = () => {
  return (
    <Routes>
      <Route path="/clients/*" element={<ClientRouter/>}/>
      <Route path="/deliveries/*" element={<DeliveryRouter/>}/>
      <Route path="/devices/*" element={<DeviceRouter/>}/>
      <Route index element={<Navigate to="/clients" replace={true}/>}/>
    </Routes>
  )
}
